import { default as _404pwP9LdbDx1Meta } from "C:/Projects/Altela/pages/404.vue?macro=true";
import { default as about_45us_45csy4pvxHxjHOMeta } from "C:/Projects/Altela/pages/about-us-cs.vue?macro=true";
import { default as about_45us_45doc5WmHfGG0N2Meta } from "C:/Projects/Altela/pages/about-us-doc.vue?macro=true";
import { default as about_45us_45eventUGUUxOwQoJMeta } from "C:/Projects/Altela/pages/about-us-event.vue?macro=true";
import { default as about_45us_45managementZAr7tOflAbMeta } from "C:/Projects/Altela/pages/about-us-management.vue?macro=true";
import { default as appointment_45schedule6mQ8arpc9PMeta } from "C:/Projects/Altela/pages/appointment-schedule.vue?macro=true";
import { default as dynamic_45detailskqMQxvdKkiMeta } from "C:/Projects/Altela/pages/blog-details/dynamic-details.vue?macro=true";
import { default as index6uD4s2z9W0Meta } from "C:/Projects/Altela/pages/blog-details/index.vue?macro=true";
import { default as blog_45v177jhNdTi2MMeta } from "C:/Projects/Altela/pages/blog-v1.vue?macro=true";
import { default as blog_45v2yPlrpCEXlPMeta } from "C:/Projects/Altela/pages/blog-v2.vue?macro=true";
import { default as blog_45v3YpLRUopZogMeta } from "C:/Projects/Altela/pages/blog-v3.vue?macro=true";
import { default as blog_45v4pXE0nmAPQAMeta } from "C:/Projects/Altela/pages/blog-v4.vue?macro=true";
import { default as cgvPkSX12TnppMeta } from "C:/Projects/Altela/pages/cgv.vue?macro=true";
import { default as changelogknyXtozz5dMeta } from "C:/Projects/Altela/pages/changelog.vue?macro=true";
import { default as coming_45soonwzOeaVwLTRMeta } from "C:/Projects/Altela/pages/coming-soon.vue?macro=true";
import { default as contact_45us_45cs0RQIpcN8VNMeta } from "C:/Projects/Altela/pages/contact-us-cs.vue?macro=true";
import { default as contact_45us_45docJCFElIKY1QMeta } from "C:/Projects/Altela/pages/contact-us-doc.vue?macro=true";
import { default as contact_45us_45eventv9vzZom3XxMeta } from "C:/Projects/Altela/pages/contact-us-event.vue?macro=true";
import { default as contact_45us_45managementa4yguMwUGAMeta } from "C:/Projects/Altela/pages/contact-us-management.vue?macro=true";
import { default as customer_45supportH3F9DkfYlaMeta } from "C:/Projects/Altela/pages/customer-support.vue?macro=true";
import { default as _91id_93oMItWVU1gnMeta } from "C:/Projects/Altela/pages/detail-produit/[idMenu]/[idSubMenu]/[id].vue?macro=true";
import { default as _91idRealisation_934C4S25P9QRMeta } from "C:/Projects/Altela/pages/detail-realisation/[titleRealisation]/[idRealisation].vue?macro=true";
import { default as doc_45box_45bannermbgAQSgoloMeta } from "C:/Projects/Altela/pages/doc-box-banner.vue?macro=true";
import { default as doc_45box4eBI3MF1lWMeta } from "C:/Projects/Altela/pages/doc-box.vue?macro=true";
import { default as doc_45full_45width_45bannerwvorbA8EzeMeta } from "C:/Projects/Altela/pages/doc-full-width-banner.vue?macro=true";
import { default as doc_45fullwidthxVuKYy29OHMeta } from "C:/Projects/Altela/pages/doc-fullwidth.vue?macro=true";
import { default as doc_45landinguVvfwTo6PWMeta } from "C:/Projects/Altela/pages/doc-landing.vue?macro=true";
import { default as doc_45signaturep8nFbXkV5SMeta } from "C:/Projects/Altela/pages/doc-signature.vue?macro=true";
import { default as index8JOeds3s03Meta } from "C:/Projects/Altela/pages/entreprise/index.vue?macro=true";
import { default as eventJLlmja2BhMMeta } from "C:/Projects/Altela/pages/event.vue?macro=true";
import { default as dynamic_45detailsyfjWS8KH9YMeta } from "C:/Projects/Altela/pages/faq-details/dynamic-details.vue?macro=true";
import { default as indexxB5wK39XQeMeta } from "C:/Projects/Altela/pages/faq-details/index.vue?macro=true";
import { default as faq_45pagez7GRU3sMTaMeta } from "C:/Projects/Altela/pages/faq-page.vue?macro=true";
import { default as feature_45cstkUNPnYVTTMeta } from "C:/Projects/Altela/pages/feature-cs.vue?macro=true";
import { default as form_45surveyyhsLw39ZLDMeta } from "C:/Projects/Altela/pages/form-survey.vue?macro=true";
import { default as indexR7Uxak2aXkMeta } from "C:/Projects/Altela/pages/home/index.vue?macro=true";
import { default as index4mharRXMuZMeta } from "C:/Projects/Altela/pages/index.vue?macro=true";
import { default as indexCKCvUBRO1pMeta } from "C:/Projects/Altela/pages/liste-realisation/index.vue?macro=true";
import { default as _91id_93oqZgc0ilkKMeta } from "C:/Projects/Altela/pages/listes-produits/[idMenu]/[idSubMenu]/[id].vue?macro=true";
import { default as loginecwNMOXv1oMeta } from "C:/Projects/Altela/pages/login.vue?macro=true";
import { default as mentions_45legalesPmMJeykfCGMeta } from "C:/Projects/Altela/pages/mentions-legales.vue?macro=true";
import { default as mobile_45app3ZVMRypxy1Meta } from "C:/Projects/Altela/pages/mobile-app.vue?macro=true";
import { default as _91idSlug_9340G8Q2jEbRMeta } from "C:/Projects/Altela/pages/nos-domaines/[slug]/[idSlug].vue?macro=true";
import { default as note_45takingjw1rODKWheMeta } from "C:/Projects/Altela/pages/note-taking.vue?macro=true";
import { default as nous_45contacterAYIcEAZSNbMeta } from "C:/Projects/Altela/pages/nous-contacter.vue?macro=true";
import { default as dynamic_45detailsnQhkZ48bOeMeta } from "C:/Projects/Altela/pages/portfolio-details/dynamic-details.vue?macro=true";
import { default as indexNxL0u4HtN9Meta } from "C:/Projects/Altela/pages/portfolio-details/index.vue?macro=true";
import { default as portfolio_45v1Qiquk63ejZMeta } from "C:/Projects/Altela/pages/portfolio-v1.vue?macro=true";
import { default as portfolio_45v275CwhshHKSMeta } from "C:/Projects/Altela/pages/portfolio-v2.vue?macro=true";
import { default as portfolio_45v3kH7kbEX0WEMeta } from "C:/Projects/Altela/pages/portfolio-v3.vue?macro=true";
import { default as portfolio_45v4k637h825l9Meta } from "C:/Projects/Altela/pages/portfolio-v4.vue?macro=true";
import { default as portfolio_45v5jkVIWS2yk6Meta } from "C:/Projects/Altela/pages/portfolio-v5.vue?macro=true";
import { default as pricing_45cs3siJrw9GQvMeta } from "C:/Projects/Altela/pages/pricing-cs.vue?macro=true";
import { default as pricing_45eventpmbKsl0GCpMeta } from "C:/Projects/Altela/pages/pricing-event.vue?macro=true";
import { default as pricing_45managementS2MTij1JV1Meta } from "C:/Projects/Altela/pages/pricing-management.vue?macro=true";
import { default as product_45cs1LAmNn5C43Meta } from "C:/Projects/Altela/pages/product-cs.vue?macro=true";
import { default as product_45landing_45darkAsNsldYuiMMeta } from "C:/Projects/Altela/pages/product-landing-dark.vue?macro=true";
import { default as product_45landing860uvWJwhcMeta } from "C:/Projects/Altela/pages/product-landing.vue?macro=true";
import { default as indexK50Iz8BhpfMeta } from "C:/Projects/Altela/pages/savoir-faire/index.vue?macro=true";
import { default as dynamic_45detailsGOIYep7zCSMeta } from "C:/Projects/Altela/pages/service-details/dynamic-details.vue?macro=true";
import { default as indexkMAWHBffhEMeta } from "C:/Projects/Altela/pages/service-details/index.vue?macro=true";
import { default as services_45v1dtVskczpTqMeta } from "C:/Projects/Altela/pages/services-v1.vue?macro=true";
import { default as services_45v2HmujKLOatkMeta } from "C:/Projects/Altela/pages/services-v2.vue?macro=true";
import { default as services_45v3o2KpvJoBoQMeta } from "C:/Projects/Altela/pages/services-v3.vue?macro=true";
import { default as services_45v4xO0E0AviPyMeta } from "C:/Projects/Altela/pages/services-v4.vue?macro=true";
import { default as sign_45upGuKc10XO7xMeta } from "C:/Projects/Altela/pages/sign-up.vue?macro=true";
import { default as solution_45managementwPbtwa7gc6Meta } from "C:/Projects/Altela/pages/solution-management.vue?macro=true";
import { default as dynamic_45details3TdEfqh1fXMeta } from "C:/Projects/Altela/pages/team-details/dynamic-details.vue?macro=true";
import { default as indexYAtiWYigqfMeta } from "C:/Projects/Altela/pages/team-details/index.vue?macro=true";
import { default as team_45v10Zp7Idv33WMeta } from "C:/Projects/Altela/pages/team-v1.vue?macro=true";
import { default as team_45v2HHWaz01vmuMeta } from "C:/Projects/Altela/pages/team-v2.vue?macro=true";
import { default as terms_45condition3Jzd5Us8B3Meta } from "C:/Projects/Altela/pages/terms-condition.vue?macro=true";
import { default as video_45editortemSM9gxvZMeta } from "C:/Projects/Altela/pages/video-editor.vue?macro=true";
import { default as vr_45landingrhuW4HfzQcMeta } from "C:/Projects/Altela/pages/vr-landing.vue?macro=true";
import { default as website_45builderXpjcpFnJv0Meta } from "C:/Projects/Altela/pages/website-builder.vue?macro=true";
export default [
  {
    name: _404pwP9LdbDx1Meta?.name ?? "404",
    path: _404pwP9LdbDx1Meta?.path ?? "/404",
    meta: _404pwP9LdbDx1Meta || {},
    alias: _404pwP9LdbDx1Meta?.alias || [],
    redirect: _404pwP9LdbDx1Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/404.vue").then(m => m.default || m)
  },
  {
    name: about_45us_45csy4pvxHxjHOMeta?.name ?? "about-us-cs",
    path: about_45us_45csy4pvxHxjHOMeta?.path ?? "/about-us-cs",
    meta: about_45us_45csy4pvxHxjHOMeta || {},
    alias: about_45us_45csy4pvxHxjHOMeta?.alias || [],
    redirect: about_45us_45csy4pvxHxjHOMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/about-us-cs.vue").then(m => m.default || m)
  },
  {
    name: about_45us_45doc5WmHfGG0N2Meta?.name ?? "about-us-doc",
    path: about_45us_45doc5WmHfGG0N2Meta?.path ?? "/about-us-doc",
    meta: about_45us_45doc5WmHfGG0N2Meta || {},
    alias: about_45us_45doc5WmHfGG0N2Meta?.alias || [],
    redirect: about_45us_45doc5WmHfGG0N2Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/about-us-doc.vue").then(m => m.default || m)
  },
  {
    name: about_45us_45eventUGUUxOwQoJMeta?.name ?? "about-us-event",
    path: about_45us_45eventUGUUxOwQoJMeta?.path ?? "/about-us-event",
    meta: about_45us_45eventUGUUxOwQoJMeta || {},
    alias: about_45us_45eventUGUUxOwQoJMeta?.alias || [],
    redirect: about_45us_45eventUGUUxOwQoJMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/about-us-event.vue").then(m => m.default || m)
  },
  {
    name: about_45us_45managementZAr7tOflAbMeta?.name ?? "about-us-management",
    path: about_45us_45managementZAr7tOflAbMeta?.path ?? "/about-us-management",
    meta: about_45us_45managementZAr7tOflAbMeta || {},
    alias: about_45us_45managementZAr7tOflAbMeta?.alias || [],
    redirect: about_45us_45managementZAr7tOflAbMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/about-us-management.vue").then(m => m.default || m)
  },
  {
    name: appointment_45schedule6mQ8arpc9PMeta?.name ?? "appointment-schedule",
    path: appointment_45schedule6mQ8arpc9PMeta?.path ?? "/appointment-schedule",
    meta: appointment_45schedule6mQ8arpc9PMeta || {},
    alias: appointment_45schedule6mQ8arpc9PMeta?.alias || [],
    redirect: appointment_45schedule6mQ8arpc9PMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/appointment-schedule.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45detailskqMQxvdKkiMeta?.name ?? "blog-details-dynamic-details",
    path: dynamic_45detailskqMQxvdKkiMeta?.path ?? "/blog-details/dynamic-details",
    meta: dynamic_45detailskqMQxvdKkiMeta || {},
    alias: dynamic_45detailskqMQxvdKkiMeta?.alias || [],
    redirect: dynamic_45detailskqMQxvdKkiMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/blog-details/dynamic-details.vue").then(m => m.default || m)
  },
  {
    name: index6uD4s2z9W0Meta?.name ?? "blog-details",
    path: index6uD4s2z9W0Meta?.path ?? "/blog-details",
    meta: index6uD4s2z9W0Meta || {},
    alias: index6uD4s2z9W0Meta?.alias || [],
    redirect: index6uD4s2z9W0Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/blog-details/index.vue").then(m => m.default || m)
  },
  {
    name: blog_45v177jhNdTi2MMeta?.name ?? "blog-v1",
    path: blog_45v177jhNdTi2MMeta?.path ?? "/blog-v1",
    meta: blog_45v177jhNdTi2MMeta || {},
    alias: blog_45v177jhNdTi2MMeta?.alias || [],
    redirect: blog_45v177jhNdTi2MMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/blog-v1.vue").then(m => m.default || m)
  },
  {
    name: blog_45v2yPlrpCEXlPMeta?.name ?? "blog-v2",
    path: blog_45v2yPlrpCEXlPMeta?.path ?? "/blog-v2",
    meta: blog_45v2yPlrpCEXlPMeta || {},
    alias: blog_45v2yPlrpCEXlPMeta?.alias || [],
    redirect: blog_45v2yPlrpCEXlPMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/blog-v2.vue").then(m => m.default || m)
  },
  {
    name: blog_45v3YpLRUopZogMeta?.name ?? "blog-v3",
    path: blog_45v3YpLRUopZogMeta?.path ?? "/blog-v3",
    meta: blog_45v3YpLRUopZogMeta || {},
    alias: blog_45v3YpLRUopZogMeta?.alias || [],
    redirect: blog_45v3YpLRUopZogMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/blog-v3.vue").then(m => m.default || m)
  },
  {
    name: blog_45v4pXE0nmAPQAMeta?.name ?? "blog-v4",
    path: blog_45v4pXE0nmAPQAMeta?.path ?? "/blog-v4",
    meta: blog_45v4pXE0nmAPQAMeta || {},
    alias: blog_45v4pXE0nmAPQAMeta?.alias || [],
    redirect: blog_45v4pXE0nmAPQAMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/blog-v4.vue").then(m => m.default || m)
  },
  {
    name: cgvPkSX12TnppMeta?.name ?? "cgv",
    path: cgvPkSX12TnppMeta?.path ?? "/cgv",
    meta: cgvPkSX12TnppMeta || {},
    alias: cgvPkSX12TnppMeta?.alias || [],
    redirect: cgvPkSX12TnppMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/cgv.vue").then(m => m.default || m)
  },
  {
    name: changelogknyXtozz5dMeta?.name ?? "changelog",
    path: changelogknyXtozz5dMeta?.path ?? "/changelog",
    meta: changelogknyXtozz5dMeta || {},
    alias: changelogknyXtozz5dMeta?.alias || [],
    redirect: changelogknyXtozz5dMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: coming_45soonwzOeaVwLTRMeta?.name ?? "coming-soon",
    path: coming_45soonwzOeaVwLTRMeta?.path ?? "/coming-soon",
    meta: coming_45soonwzOeaVwLTRMeta || {},
    alias: coming_45soonwzOeaVwLTRMeta?.alias || [],
    redirect: coming_45soonwzOeaVwLTRMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/coming-soon.vue").then(m => m.default || m)
  },
  {
    name: contact_45us_45cs0RQIpcN8VNMeta?.name ?? "contact-us-cs",
    path: contact_45us_45cs0RQIpcN8VNMeta?.path ?? "/contact-us-cs",
    meta: contact_45us_45cs0RQIpcN8VNMeta || {},
    alias: contact_45us_45cs0RQIpcN8VNMeta?.alias || [],
    redirect: contact_45us_45cs0RQIpcN8VNMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/contact-us-cs.vue").then(m => m.default || m)
  },
  {
    name: contact_45us_45docJCFElIKY1QMeta?.name ?? "contact-us-doc",
    path: contact_45us_45docJCFElIKY1QMeta?.path ?? "/contact-us-doc",
    meta: contact_45us_45docJCFElIKY1QMeta || {},
    alias: contact_45us_45docJCFElIKY1QMeta?.alias || [],
    redirect: contact_45us_45docJCFElIKY1QMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/contact-us-doc.vue").then(m => m.default || m)
  },
  {
    name: contact_45us_45eventv9vzZom3XxMeta?.name ?? "contact-us-event",
    path: contact_45us_45eventv9vzZom3XxMeta?.path ?? "/contact-us-event",
    meta: contact_45us_45eventv9vzZom3XxMeta || {},
    alias: contact_45us_45eventv9vzZom3XxMeta?.alias || [],
    redirect: contact_45us_45eventv9vzZom3XxMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/contact-us-event.vue").then(m => m.default || m)
  },
  {
    name: contact_45us_45managementa4yguMwUGAMeta?.name ?? "contact-us-management",
    path: contact_45us_45managementa4yguMwUGAMeta?.path ?? "/contact-us-management",
    meta: contact_45us_45managementa4yguMwUGAMeta || {},
    alias: contact_45us_45managementa4yguMwUGAMeta?.alias || [],
    redirect: contact_45us_45managementa4yguMwUGAMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/contact-us-management.vue").then(m => m.default || m)
  },
  {
    name: customer_45supportH3F9DkfYlaMeta?.name ?? "customer-support",
    path: customer_45supportH3F9DkfYlaMeta?.path ?? "/customer-support",
    meta: customer_45supportH3F9DkfYlaMeta || {},
    alias: customer_45supportH3F9DkfYlaMeta?.alias || [],
    redirect: customer_45supportH3F9DkfYlaMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/customer-support.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oMItWVU1gnMeta?.name ?? "detail-produit-idMenu-idSubMenu-id",
    path: _91id_93oMItWVU1gnMeta?.path ?? "/detail-produit/:idMenu()/:idSubMenu()/:id()",
    meta: _91id_93oMItWVU1gnMeta || {},
    alias: _91id_93oMItWVU1gnMeta?.alias || [],
    redirect: _91id_93oMItWVU1gnMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/detail-produit/[idMenu]/[idSubMenu]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91idRealisation_934C4S25P9QRMeta?.name ?? "detail-realisation-titleRealisation-idRealisation",
    path: _91idRealisation_934C4S25P9QRMeta?.path ?? "/detail-realisation/:titleRealisation()/:idRealisation()",
    meta: _91idRealisation_934C4S25P9QRMeta || {},
    alias: _91idRealisation_934C4S25P9QRMeta?.alias || [],
    redirect: _91idRealisation_934C4S25P9QRMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/detail-realisation/[titleRealisation]/[idRealisation].vue").then(m => m.default || m)
  },
  {
    name: doc_45box_45bannermbgAQSgoloMeta?.name ?? "doc-box-banner",
    path: doc_45box_45bannermbgAQSgoloMeta?.path ?? "/doc-box-banner",
    meta: doc_45box_45bannermbgAQSgoloMeta || {},
    alias: doc_45box_45bannermbgAQSgoloMeta?.alias || [],
    redirect: doc_45box_45bannermbgAQSgoloMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/doc-box-banner.vue").then(m => m.default || m)
  },
  {
    name: doc_45box4eBI3MF1lWMeta?.name ?? "doc-box",
    path: doc_45box4eBI3MF1lWMeta?.path ?? "/doc-box",
    meta: doc_45box4eBI3MF1lWMeta || {},
    alias: doc_45box4eBI3MF1lWMeta?.alias || [],
    redirect: doc_45box4eBI3MF1lWMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/doc-box.vue").then(m => m.default || m)
  },
  {
    name: doc_45full_45width_45bannerwvorbA8EzeMeta?.name ?? "doc-full-width-banner",
    path: doc_45full_45width_45bannerwvorbA8EzeMeta?.path ?? "/doc-full-width-banner",
    meta: doc_45full_45width_45bannerwvorbA8EzeMeta || {},
    alias: doc_45full_45width_45bannerwvorbA8EzeMeta?.alias || [],
    redirect: doc_45full_45width_45bannerwvorbA8EzeMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/doc-full-width-banner.vue").then(m => m.default || m)
  },
  {
    name: doc_45fullwidthxVuKYy29OHMeta?.name ?? "doc-fullwidth",
    path: doc_45fullwidthxVuKYy29OHMeta?.path ?? "/doc-fullwidth",
    meta: doc_45fullwidthxVuKYy29OHMeta || {},
    alias: doc_45fullwidthxVuKYy29OHMeta?.alias || [],
    redirect: doc_45fullwidthxVuKYy29OHMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/doc-fullwidth.vue").then(m => m.default || m)
  },
  {
    name: doc_45landinguVvfwTo6PWMeta?.name ?? "doc-landing",
    path: doc_45landinguVvfwTo6PWMeta?.path ?? "/doc-landing",
    meta: doc_45landinguVvfwTo6PWMeta || {},
    alias: doc_45landinguVvfwTo6PWMeta?.alias || [],
    redirect: doc_45landinguVvfwTo6PWMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/doc-landing.vue").then(m => m.default || m)
  },
  {
    name: doc_45signaturep8nFbXkV5SMeta?.name ?? "doc-signature",
    path: doc_45signaturep8nFbXkV5SMeta?.path ?? "/doc-signature",
    meta: doc_45signaturep8nFbXkV5SMeta || {},
    alias: doc_45signaturep8nFbXkV5SMeta?.alias || [],
    redirect: doc_45signaturep8nFbXkV5SMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/doc-signature.vue").then(m => m.default || m)
  },
  {
    name: index8JOeds3s03Meta?.name ?? "entreprise",
    path: index8JOeds3s03Meta?.path ?? "/entreprise",
    meta: index8JOeds3s03Meta || {},
    alias: index8JOeds3s03Meta?.alias || [],
    redirect: index8JOeds3s03Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/entreprise/index.vue").then(m => m.default || m)
  },
  {
    name: eventJLlmja2BhMMeta?.name ?? "event",
    path: eventJLlmja2BhMMeta?.path ?? "/event",
    meta: eventJLlmja2BhMMeta || {},
    alias: eventJLlmja2BhMMeta?.alias || [],
    redirect: eventJLlmja2BhMMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/event.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45detailsyfjWS8KH9YMeta?.name ?? "faq-details-dynamic-details",
    path: dynamic_45detailsyfjWS8KH9YMeta?.path ?? "/faq-details/dynamic-details",
    meta: dynamic_45detailsyfjWS8KH9YMeta || {},
    alias: dynamic_45detailsyfjWS8KH9YMeta?.alias || [],
    redirect: dynamic_45detailsyfjWS8KH9YMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/faq-details/dynamic-details.vue").then(m => m.default || m)
  },
  {
    name: indexxB5wK39XQeMeta?.name ?? "faq-details",
    path: indexxB5wK39XQeMeta?.path ?? "/faq-details",
    meta: indexxB5wK39XQeMeta || {},
    alias: indexxB5wK39XQeMeta?.alias || [],
    redirect: indexxB5wK39XQeMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/faq-details/index.vue").then(m => m.default || m)
  },
  {
    name: faq_45pagez7GRU3sMTaMeta?.name ?? "faq-page",
    path: faq_45pagez7GRU3sMTaMeta?.path ?? "/faq-page",
    meta: faq_45pagez7GRU3sMTaMeta || {},
    alias: faq_45pagez7GRU3sMTaMeta?.alias || [],
    redirect: faq_45pagez7GRU3sMTaMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/faq-page.vue").then(m => m.default || m)
  },
  {
    name: feature_45cstkUNPnYVTTMeta?.name ?? "feature-cs",
    path: feature_45cstkUNPnYVTTMeta?.path ?? "/feature-cs",
    meta: feature_45cstkUNPnYVTTMeta || {},
    alias: feature_45cstkUNPnYVTTMeta?.alias || [],
    redirect: feature_45cstkUNPnYVTTMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/feature-cs.vue").then(m => m.default || m)
  },
  {
    name: form_45surveyyhsLw39ZLDMeta?.name ?? "form-survey",
    path: form_45surveyyhsLw39ZLDMeta?.path ?? "/form-survey",
    meta: form_45surveyyhsLw39ZLDMeta || {},
    alias: form_45surveyyhsLw39ZLDMeta?.alias || [],
    redirect: form_45surveyyhsLw39ZLDMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/form-survey.vue").then(m => m.default || m)
  },
  {
    name: indexR7Uxak2aXkMeta?.name ?? "home",
    path: indexR7Uxak2aXkMeta?.path ?? "/home",
    meta: indexR7Uxak2aXkMeta || {},
    alias: indexR7Uxak2aXkMeta?.alias || [],
    redirect: indexR7Uxak2aXkMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: index4mharRXMuZMeta?.name ?? "index",
    path: index4mharRXMuZMeta?.path ?? "/",
    meta: index4mharRXMuZMeta || {},
    alias: index4mharRXMuZMeta?.alias || [],
    redirect: index4mharRXMuZMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexCKCvUBRO1pMeta?.name ?? "liste-realisation",
    path: indexCKCvUBRO1pMeta?.path ?? "/liste-realisation",
    meta: indexCKCvUBRO1pMeta || {},
    alias: indexCKCvUBRO1pMeta?.alias || [],
    redirect: indexCKCvUBRO1pMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/liste-realisation/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oqZgc0ilkKMeta?.name ?? "listes-produits-idMenu-idSubMenu-id",
    path: _91id_93oqZgc0ilkKMeta?.path ?? "/listes-produits/:idMenu()/:idSubMenu()/:id()",
    meta: _91id_93oqZgc0ilkKMeta || {},
    alias: _91id_93oqZgc0ilkKMeta?.alias || [],
    redirect: _91id_93oqZgc0ilkKMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/listes-produits/[idMenu]/[idSubMenu]/[id].vue").then(m => m.default || m)
  },
  {
    name: loginecwNMOXv1oMeta?.name ?? "login",
    path: loginecwNMOXv1oMeta?.path ?? "/login",
    meta: loginecwNMOXv1oMeta || {},
    alias: loginecwNMOXv1oMeta?.alias || [],
    redirect: loginecwNMOXv1oMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mentions_45legalesPmMJeykfCGMeta?.name ?? "mentions-legales",
    path: mentions_45legalesPmMJeykfCGMeta?.path ?? "/mentions-legales",
    meta: mentions_45legalesPmMJeykfCGMeta || {},
    alias: mentions_45legalesPmMJeykfCGMeta?.alias || [],
    redirect: mentions_45legalesPmMJeykfCGMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app3ZVMRypxy1Meta?.name ?? "mobile-app",
    path: mobile_45app3ZVMRypxy1Meta?.path ?? "/mobile-app",
    meta: mobile_45app3ZVMRypxy1Meta || {},
    alias: mobile_45app3ZVMRypxy1Meta?.alias || [],
    redirect: mobile_45app3ZVMRypxy1Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: _91idSlug_9340G8Q2jEbRMeta?.name ?? "nos-domaines-slug-idSlug",
    path: _91idSlug_9340G8Q2jEbRMeta?.path ?? "/nos-domaines/:slug()/:idSlug()",
    meta: _91idSlug_9340G8Q2jEbRMeta || {},
    alias: _91idSlug_9340G8Q2jEbRMeta?.alias || [],
    redirect: _91idSlug_9340G8Q2jEbRMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/nos-domaines/[slug]/[idSlug].vue").then(m => m.default || m)
  },
  {
    name: note_45takingjw1rODKWheMeta?.name ?? "note-taking",
    path: note_45takingjw1rODKWheMeta?.path ?? "/note-taking",
    meta: note_45takingjw1rODKWheMeta || {},
    alias: note_45takingjw1rODKWheMeta?.alias || [],
    redirect: note_45takingjw1rODKWheMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/note-taking.vue").then(m => m.default || m)
  },
  {
    name: nous_45contacterAYIcEAZSNbMeta?.name ?? "nous-contacter",
    path: nous_45contacterAYIcEAZSNbMeta?.path ?? "/nous-contacter",
    meta: nous_45contacterAYIcEAZSNbMeta || {},
    alias: nous_45contacterAYIcEAZSNbMeta?.alias || [],
    redirect: nous_45contacterAYIcEAZSNbMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/nous-contacter.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45detailsnQhkZ48bOeMeta?.name ?? "portfolio-details-dynamic-details",
    path: dynamic_45detailsnQhkZ48bOeMeta?.path ?? "/portfolio-details/dynamic-details",
    meta: dynamic_45detailsnQhkZ48bOeMeta || {},
    alias: dynamic_45detailsnQhkZ48bOeMeta?.alias || [],
    redirect: dynamic_45detailsnQhkZ48bOeMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/portfolio-details/dynamic-details.vue").then(m => m.default || m)
  },
  {
    name: indexNxL0u4HtN9Meta?.name ?? "portfolio-details",
    path: indexNxL0u4HtN9Meta?.path ?? "/portfolio-details",
    meta: indexNxL0u4HtN9Meta || {},
    alias: indexNxL0u4HtN9Meta?.alias || [],
    redirect: indexNxL0u4HtN9Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/portfolio-details/index.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45v1Qiquk63ejZMeta?.name ?? "portfolio-v1",
    path: portfolio_45v1Qiquk63ejZMeta?.path ?? "/portfolio-v1",
    meta: portfolio_45v1Qiquk63ejZMeta || {},
    alias: portfolio_45v1Qiquk63ejZMeta?.alias || [],
    redirect: portfolio_45v1Qiquk63ejZMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/portfolio-v1.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45v275CwhshHKSMeta?.name ?? "portfolio-v2",
    path: portfolio_45v275CwhshHKSMeta?.path ?? "/portfolio-v2",
    meta: portfolio_45v275CwhshHKSMeta || {},
    alias: portfolio_45v275CwhshHKSMeta?.alias || [],
    redirect: portfolio_45v275CwhshHKSMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/portfolio-v2.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45v3kH7kbEX0WEMeta?.name ?? "portfolio-v3",
    path: portfolio_45v3kH7kbEX0WEMeta?.path ?? "/portfolio-v3",
    meta: portfolio_45v3kH7kbEX0WEMeta || {},
    alias: portfolio_45v3kH7kbEX0WEMeta?.alias || [],
    redirect: portfolio_45v3kH7kbEX0WEMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/portfolio-v3.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45v4k637h825l9Meta?.name ?? "portfolio-v4",
    path: portfolio_45v4k637h825l9Meta?.path ?? "/portfolio-v4",
    meta: portfolio_45v4k637h825l9Meta || {},
    alias: portfolio_45v4k637h825l9Meta?.alias || [],
    redirect: portfolio_45v4k637h825l9Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/portfolio-v4.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45v5jkVIWS2yk6Meta?.name ?? "portfolio-v5",
    path: portfolio_45v5jkVIWS2yk6Meta?.path ?? "/portfolio-v5",
    meta: portfolio_45v5jkVIWS2yk6Meta || {},
    alias: portfolio_45v5jkVIWS2yk6Meta?.alias || [],
    redirect: portfolio_45v5jkVIWS2yk6Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/portfolio-v5.vue").then(m => m.default || m)
  },
  {
    name: pricing_45cs3siJrw9GQvMeta?.name ?? "pricing-cs",
    path: pricing_45cs3siJrw9GQvMeta?.path ?? "/pricing-cs",
    meta: pricing_45cs3siJrw9GQvMeta || {},
    alias: pricing_45cs3siJrw9GQvMeta?.alias || [],
    redirect: pricing_45cs3siJrw9GQvMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/pricing-cs.vue").then(m => m.default || m)
  },
  {
    name: pricing_45eventpmbKsl0GCpMeta?.name ?? "pricing-event",
    path: pricing_45eventpmbKsl0GCpMeta?.path ?? "/pricing-event",
    meta: pricing_45eventpmbKsl0GCpMeta || {},
    alias: pricing_45eventpmbKsl0GCpMeta?.alias || [],
    redirect: pricing_45eventpmbKsl0GCpMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/pricing-event.vue").then(m => m.default || m)
  },
  {
    name: pricing_45managementS2MTij1JV1Meta?.name ?? "pricing-management",
    path: pricing_45managementS2MTij1JV1Meta?.path ?? "/pricing-management",
    meta: pricing_45managementS2MTij1JV1Meta || {},
    alias: pricing_45managementS2MTij1JV1Meta?.alias || [],
    redirect: pricing_45managementS2MTij1JV1Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/pricing-management.vue").then(m => m.default || m)
  },
  {
    name: product_45cs1LAmNn5C43Meta?.name ?? "product-cs",
    path: product_45cs1LAmNn5C43Meta?.path ?? "/product-cs",
    meta: product_45cs1LAmNn5C43Meta || {},
    alias: product_45cs1LAmNn5C43Meta?.alias || [],
    redirect: product_45cs1LAmNn5C43Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/product-cs.vue").then(m => m.default || m)
  },
  {
    name: product_45landing_45darkAsNsldYuiMMeta?.name ?? "product-landing-dark",
    path: product_45landing_45darkAsNsldYuiMMeta?.path ?? "/product-landing-dark",
    meta: product_45landing_45darkAsNsldYuiMMeta || {},
    alias: product_45landing_45darkAsNsldYuiMMeta?.alias || [],
    redirect: product_45landing_45darkAsNsldYuiMMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/product-landing-dark.vue").then(m => m.default || m)
  },
  {
    name: product_45landing860uvWJwhcMeta?.name ?? "product-landing",
    path: product_45landing860uvWJwhcMeta?.path ?? "/product-landing",
    meta: product_45landing860uvWJwhcMeta || {},
    alias: product_45landing860uvWJwhcMeta?.alias || [],
    redirect: product_45landing860uvWJwhcMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/product-landing.vue").then(m => m.default || m)
  },
  {
    name: indexK50Iz8BhpfMeta?.name ?? "savoir-faire",
    path: indexK50Iz8BhpfMeta?.path ?? "/savoir-faire",
    meta: indexK50Iz8BhpfMeta || {},
    alias: indexK50Iz8BhpfMeta?.alias || [],
    redirect: indexK50Iz8BhpfMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/savoir-faire/index.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45detailsGOIYep7zCSMeta?.name ?? "service-details-dynamic-details",
    path: dynamic_45detailsGOIYep7zCSMeta?.path ?? "/service-details/dynamic-details",
    meta: dynamic_45detailsGOIYep7zCSMeta || {},
    alias: dynamic_45detailsGOIYep7zCSMeta?.alias || [],
    redirect: dynamic_45detailsGOIYep7zCSMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/service-details/dynamic-details.vue").then(m => m.default || m)
  },
  {
    name: indexkMAWHBffhEMeta?.name ?? "service-details",
    path: indexkMAWHBffhEMeta?.path ?? "/service-details",
    meta: indexkMAWHBffhEMeta || {},
    alias: indexkMAWHBffhEMeta?.alias || [],
    redirect: indexkMAWHBffhEMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/service-details/index.vue").then(m => m.default || m)
  },
  {
    name: services_45v1dtVskczpTqMeta?.name ?? "services-v1",
    path: services_45v1dtVskczpTqMeta?.path ?? "/services-v1",
    meta: services_45v1dtVskczpTqMeta || {},
    alias: services_45v1dtVskczpTqMeta?.alias || [],
    redirect: services_45v1dtVskczpTqMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/services-v1.vue").then(m => m.default || m)
  },
  {
    name: services_45v2HmujKLOatkMeta?.name ?? "services-v2",
    path: services_45v2HmujKLOatkMeta?.path ?? "/services-v2",
    meta: services_45v2HmujKLOatkMeta || {},
    alias: services_45v2HmujKLOatkMeta?.alias || [],
    redirect: services_45v2HmujKLOatkMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/services-v2.vue").then(m => m.default || m)
  },
  {
    name: services_45v3o2KpvJoBoQMeta?.name ?? "services-v3",
    path: services_45v3o2KpvJoBoQMeta?.path ?? "/services-v3",
    meta: services_45v3o2KpvJoBoQMeta || {},
    alias: services_45v3o2KpvJoBoQMeta?.alias || [],
    redirect: services_45v3o2KpvJoBoQMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/services-v3.vue").then(m => m.default || m)
  },
  {
    name: services_45v4xO0E0AviPyMeta?.name ?? "services-v4",
    path: services_45v4xO0E0AviPyMeta?.path ?? "/services-v4",
    meta: services_45v4xO0E0AviPyMeta || {},
    alias: services_45v4xO0E0AviPyMeta?.alias || [],
    redirect: services_45v4xO0E0AviPyMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/services-v4.vue").then(m => m.default || m)
  },
  {
    name: sign_45upGuKc10XO7xMeta?.name ?? "sign-up",
    path: sign_45upGuKc10XO7xMeta?.path ?? "/sign-up",
    meta: sign_45upGuKc10XO7xMeta || {},
    alias: sign_45upGuKc10XO7xMeta?.alias || [],
    redirect: sign_45upGuKc10XO7xMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: solution_45managementwPbtwa7gc6Meta?.name ?? "solution-management",
    path: solution_45managementwPbtwa7gc6Meta?.path ?? "/solution-management",
    meta: solution_45managementwPbtwa7gc6Meta || {},
    alias: solution_45managementwPbtwa7gc6Meta?.alias || [],
    redirect: solution_45managementwPbtwa7gc6Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/solution-management.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45details3TdEfqh1fXMeta?.name ?? "team-details-dynamic-details",
    path: dynamic_45details3TdEfqh1fXMeta?.path ?? "/team-details/dynamic-details",
    meta: dynamic_45details3TdEfqh1fXMeta || {},
    alias: dynamic_45details3TdEfqh1fXMeta?.alias || [],
    redirect: dynamic_45details3TdEfqh1fXMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/team-details/dynamic-details.vue").then(m => m.default || m)
  },
  {
    name: indexYAtiWYigqfMeta?.name ?? "team-details",
    path: indexYAtiWYigqfMeta?.path ?? "/team-details",
    meta: indexYAtiWYigqfMeta || {},
    alias: indexYAtiWYigqfMeta?.alias || [],
    redirect: indexYAtiWYigqfMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/team-details/index.vue").then(m => m.default || m)
  },
  {
    name: team_45v10Zp7Idv33WMeta?.name ?? "team-v1",
    path: team_45v10Zp7Idv33WMeta?.path ?? "/team-v1",
    meta: team_45v10Zp7Idv33WMeta || {},
    alias: team_45v10Zp7Idv33WMeta?.alias || [],
    redirect: team_45v10Zp7Idv33WMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/team-v1.vue").then(m => m.default || m)
  },
  {
    name: team_45v2HHWaz01vmuMeta?.name ?? "team-v2",
    path: team_45v2HHWaz01vmuMeta?.path ?? "/team-v2",
    meta: team_45v2HHWaz01vmuMeta || {},
    alias: team_45v2HHWaz01vmuMeta?.alias || [],
    redirect: team_45v2HHWaz01vmuMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/team-v2.vue").then(m => m.default || m)
  },
  {
    name: terms_45condition3Jzd5Us8B3Meta?.name ?? "terms-condition",
    path: terms_45condition3Jzd5Us8B3Meta?.path ?? "/terms-condition",
    meta: terms_45condition3Jzd5Us8B3Meta || {},
    alias: terms_45condition3Jzd5Us8B3Meta?.alias || [],
    redirect: terms_45condition3Jzd5Us8B3Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/terms-condition.vue").then(m => m.default || m)
  },
  {
    name: video_45editortemSM9gxvZMeta?.name ?? "video-editor",
    path: video_45editortemSM9gxvZMeta?.path ?? "/video-editor",
    meta: video_45editortemSM9gxvZMeta || {},
    alias: video_45editortemSM9gxvZMeta?.alias || [],
    redirect: video_45editortemSM9gxvZMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/video-editor.vue").then(m => m.default || m)
  },
  {
    name: vr_45landingrhuW4HfzQcMeta?.name ?? "vr-landing",
    path: vr_45landingrhuW4HfzQcMeta?.path ?? "/vr-landing",
    meta: vr_45landingrhuW4HfzQcMeta || {},
    alias: vr_45landingrhuW4HfzQcMeta?.alias || [],
    redirect: vr_45landingrhuW4HfzQcMeta?.redirect,
    component: () => import("C:/Projects/Altela/pages/vr-landing.vue").then(m => m.default || m)
  },
  {
    name: website_45builderXpjcpFnJv0Meta?.name ?? "website-builder",
    path: website_45builderXpjcpFnJv0Meta?.path ?? "/website-builder",
    meta: website_45builderXpjcpFnJv0Meta || {},
    alias: website_45builderXpjcpFnJv0Meta?.alias || [],
    redirect: website_45builderXpjcpFnJv0Meta?.redirect,
    component: () => import("C:/Projects/Altela/pages/website-builder.vue").then(m => m.default || m)
  }
]